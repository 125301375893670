<template>
  <strong>{{ getPrice() }}</strong>
</template>

<script>
import { formatPrice } from "../../../../helpers/common";

export default {
  name: "ProductTableVariantPrice",

  methods: {
    getPrice() {
      const currencySymbol = this.$store.getters["connections/currencySymbol"];
      return formatPrice(this.data.price, currencySymbol).format();
    },
  },
};
</script>
